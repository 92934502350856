export const useContentSections = () => {
  return {
    
    // blocks
    'logos_block': 'BlockLogos',
    'text_block': 'BlockText',
    'articles_block': 'BlockArticles',
    'cta_block': 'BlockCtas',
    'faq_block': 'BlockFaqs',
    'compliance_block': 'BlockCompliance',
    'trust_block': 'BlockTrust',
    'use_cases_block': 'BlockUseCases',
    'bricks_block': 'BlockBricks',
    'features_block': 'BlockFeatures',
    'three_block': 'BlockStats',
    'solutions_block': 'BlockSolutions',
    'backed_by_data_block': 'BlockBackedByData',
    'video_block': 'BlockVideo',
    'usp_block': 'BlockUsps',
    'resources_block': 'BlockResources',
    'testimonial_block': 'BlockTestimonial',
    
    // elements
    'content': 'ElementContent',
    'image': 'ElementImage',
    'quote': 'ElementQuote',
    'hero': 'ElementHero',
    'video': 'ElementVideo',
    'highlight_box': 'ElementHighlight',
    'product_block': 'ElementProduct',

    // remove
    // 'shared.cards-block': 'BlockCards',
    // 'shared.showcase-block': 'BlockShowcase',
    // 'shared.highlights-block': 'BlockHighlights',
    // 'shared.how-it-works': 'BlockHowItWorks',

  }
}